import { forkJoin, map, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/core';

export class MultiTranslateBrowserLoader implements TranslateLoader {
  constructor(
    private readonly name: string,
    private readonly paths: string[],
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(
      `transfer-translate-${this.name}-${lang}`
    );
    const data = this.transferState.get(key, null);

    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    }

    const requests = this.paths.map((path) => {
      const url = `/assets/i18n/${path}/${lang}.json`;
      return this.http.get(url);
    });

    return forkJoin(requests).pipe(
      map((responses) => {
        let mergedTranslations = {};
        responses.forEach((translation) => {
          if (typeof translation === 'object') {
            mergedTranslations = { ...mergedTranslations, ...translation };
          }
        });
        return mergedTranslations;
      })
    );
  }
}

export function translateMultiBrowserLoaderFactory(
  name: string,
  http: HttpClient,
  transferState: TransferState,
  paths: string[]
): MultiTranslateBrowserLoader {
  return new MultiTranslateBrowserLoader(name, paths, http, transferState);
}
