import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class Translation {
  static useLang(translateService: TranslateService, lang = 'pt-br') {
    translateService.currentLang = '';
    translateService.use(lang);
  }

  static useLangAsync(
    translateService: TranslateService,
    lang = 'pt-br'
  ): Observable<any> {
    translateService.currentLang = '';
    return translateService.use(lang);
  }
}
