{
  "faq": "faq",
  "residential-content": "residential-content",
  "buildings-content": "buildings-content",
  "commerce-content": "commerce-content",
  "vehicles-content": "vehicles-content",
  "industries-content": "industries-content",
  "swimming-pool-content": "swimming-pool-content",
  "address-validation": "address-validation",
  "personal-data": "personal-data",
  "scheduling": "scheduling",
  "equipment-selection": "equipment-selection",
  "protocol": "protocol",
  "feedback": "feedback",
  "commonPaths": ["common", "chat", "error", "home"],
  "blog": "blog"
}
