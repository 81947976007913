import { Observable, retry } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export class TranslateServerLoader implements TranslateLoader {
  constructor(
    private readonly path: string,
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      const url = `/assets/i18n/${this.path}/${lang}.json`;
      this.http
        .get(url)
        .pipe(retry(3))
        .subscribe({
          next: (jsonData) => {
            const key: StateKey<number> = makeStateKey<number>(
              `transfer-translate-${this.path}-${lang}`
            );
            this.transferState.set(key, jsonData);

            observer.next(jsonData);
            observer.complete();
          },
          error: (err) => {
            console.error('Error fetching translation:', err);
            observer.error(err);
          },
        });
    });
  }
}

export function translateServerLoaderFactory(
  path: string,
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateServerLoader(path, http, transferState);
}
