import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/core';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private readonly path: string,
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(
      `transfer-translate-${this.path}-${lang}`
    );
    const data = this.transferState.get(key, null);

    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    } else {
      const url = `/assets/i18n/${this.path}/`;
      return new TranslateHttpLoader(this.http, url).getTranslation(lang);
    }
  }
}

export function translateBrowserLoaderFactory(
  path: string,
  httpClient: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(path, httpClient, transferState);
}
